/* DatePickerStyles.css */
.react-datepicker-wrapper{
    width: 190px;
    display: flex;
  }
  .react-datepicker {
      background-color: white; /* Change the background color */
    }
    
    .react-datepicker__day-name {
      color: #333; /* Change the day names color */
    }
  
    .Date-filter-inner-container{
      color: white;
    }
    
    .react-datepicker__input-container input {
      width: 145px;
    }
  
    .react-datepicker__close-icon{
        display: none;
      color: grey;
    }