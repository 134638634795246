.settings-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-details {
    border-radius: 10px;
    border: 2px solid #000000;
    padding: 10px;
    /* max-width: 350px; */
    /* min-width: 350x; */
    width: 325px;
}

.user-details-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.user-details-value {
    background-color: #D3D3D3;
    width: 100px;
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: none
}
