button {
    background-color: black;
    border-radius: 10px;
    margin: 10px;
    color:white;
    font-size: 1.5rem;
    border: 1px solid white;
    cursor: pointer;
}

.pullToRefresh {
    min-height: 100vh;
    padding-bottom: 100px;
}

.start-trading-button {
    padding: 5px 10px 5px 10px;
}

.analytics-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.analytics-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    /* height: 60vh; */
    margin-top: 30px;
}

.date-filter-main-container {
    margin-top: 10px;
}

.positions {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 30px;
}


.trading-button {
    padding: 10px 50px 10px 50px;
}

.trail-button {
    padding: 10px 30px 10px 30px;
}

.partial-exit-button {
    padding: 10px 30px 10px 30px;
}

.exit-button {
    padding: 10px 100px 10px 100px;
}

.pnl {
    margin-top: 10px;
    padding: 10px 30px 10px 30px;
    font-size: 32px;
    font-weight: 700;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
}