.positions {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 30px;
}

.all-positions {
    font-size: 18px;
}

.pnl {
    margin-top: 10px;
    padding: 10px 30px 10px 30px;
    font-size: 32px;
    font-weight: 700;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
}